var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"electricity-tariff pa-10"},[_c('title-page',{staticClass:"mb-0",attrs:{"icon":"ico-carplug"}},[_vm._v(" "+_vm._s(_vm.$t("electricity-tariff.add"))+" ")]),_c('v-breadcrumbs',{attrs:{"items":[
          {text: _vm.$tc('electricity-index.electricity-index', 2), to: {name: 'electricity-index'}, exact: true},
          {text: _vm.electricityIndex?.name, to: {name: 'electricity-tariff'}, exact: true},
          {text: _vm.$tc( _vm.isEdit ? 'electricity-tariff.edit' : 'electricity-tariff.add', 2)},
      ],"divider":">"}}),_c('div',{staticClass:"mt-5"},[_c('headline',[_vm._v(_vm._s(_vm.$tc('electricity-tariff.electricity-tariff')))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('form-field',{attrs:{"type":"date","label":_vm.$t('electricity-tariff.validity-start-date'),"rules":[
                      _vm.commonRules.required,
                  ]},model:{value:(_vm.form.validityStartDate),callback:function ($$v) {_vm.$set(_vm.form, "validityStartDate", $$v)},expression:"form.validityStartDate"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('form-field',{attrs:{"type":"date","label":_vm.$t('electricity-tariff.validity-end-date'),"rules":[
                      _vm.commonRules.required,
                  ],"col-field":"9"},model:{value:(_vm.form.validityEndDate),callback:function ($$v) {_vm.$set(_vm.form, "validityEndDate", $$v)},expression:"form.validityEndDate"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('form-field',{attrs:{"input-type":"number","label":_vm.$t('electricity-tariff.value-per-kwh'),"rules":[
                      _vm.commonRules.required,
                  ],"col-field":"9"},model:{value:(_vm.form.valuePerKWh),callback:function ($$v) {_vm.$set(_vm.form, "valuePerKWh", _vm._n($$v))},expression:"form.valuePerKWh"}})],1)],1),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"type":"submit","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1),_c('v-divider',{staticClass:"my-10"}),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"small":""},on:{"click":_vm.deleteItem}},[_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }